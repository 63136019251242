import React from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "../../assets/Logo.svg";
import Fb from "../../assets/FB.svg";
import Instagram from "../../assets/Instagram.svg";
import Linkedin from "../../assets/Linkedin.svg";
import SuccessImage from "../../assets/success.png";
import Error from "../../assets/error.svg";
import useFooter from "../../hooks/useFooter";
import { InviteFriendModal, Success } from "../../modals";
import Envelope from "../../assets/InviteFriendEnvelope.png";

import "./Footer.scss";

const Footer = () => {
  const {
    isModalOpened,
    closeModal,
    openModal,
    title,
    text,
    email,
    handleInput,
    subsCribe,
    isSuccessModalOpened,
    closeSuccessModal,
    openSuccessModal,
    checkEmail,
    showError,
    emailError
  } = useFooter();
  const { pathname } = useLocation();
  if (
    pathname.includes("signup") ||
    pathname.includes("signin") ||
    pathname.includes("forgotpassword") ||
    pathname.includes("scenario") ||
    pathname.includes("profile")
  ) {
    return <></>;
  }
  return (
    <footer className="Footer">
      <div className="Footer-email">
        <div className="Footer-email-inner">
          <p className="Footer-email__content">Stay in touch</p>
          <input
            placeholder="Email"
            className="Footer-email__input"
            value={email}
            onChange={handleInput}
          />
          <button className="Footer-email__button" onClick={() => {
            checkEmail(email)
          }}>
            Subscribe
          </button>
        </div>
        {showError ? (
          <div className="errorCont">
            <img src={Error} alt="Error" />
            <p className="errorCont__text">{emailError}</p>
          </div>
        ) : null}
      </div>
      <div className="Footer-container">
        <div className="Footer-container-content">
          <div className="Footer-container-content__info">
            <div className="Footer-container-content__info__logo">
              <img src={Logo} alt="" />
            </div>
            <p>
              We assist young adults with the knowledge they need to make
              informed decisions about their future career.
            </p>
          </div>
          {/* <div className="Footer-container-content__product">
            <p className="Footer-container-content__product__title">Product</p>
            <p className="Footer-container-content__product__item">Resources</p>
            <p className="Footer-container-content__product__item">Pricing</p>
            <p className="Footer-container-content__product__item">Reviews</p>
          </div> */}
          <div className="Footer-container-content__company">
            <p className="Footer-container-content__company__title">Company</p>
            <p className="Footer-container-content__company__item">
              <Link to="/about">About</Link>
            </p>
            <p
              className="Footer-container-content__company__item"
              onClick={() => {
                openModal(
                  "Contact us",
                  "Please feel free to leave us a detailed message below, and we'll make sure to get back to you promptly. You can also reach us by phone at (407) 205-7872 or email us directly at info@career-scoops.com"
                );
              }}
            >
              Contact us
            </p>
            <p className="Footer-container-content__company__item">
              <Link to="/stories">Stories</Link>
            </p>
          </div>
          <div className="Footer-container-content__support">
            <p className="Footer-container-content__support__title">Support</p>
            <p
              className="Footer-container-content__support__item"
              onClick={() => {
                openModal(
                  "Chat support",
                  "Thank you for reaching out to Career Scoops™ chat support! Please leave us a message, and an available Career Scoops representative will get back to you shortly. You can also reach us via text at (407) 205-7872."
                );
              }}
            >
              Chat support
            </p>
            <p
              className="Footer-container-content__support__item"
              onClick={() => {
                openModal(
                  "Report a bug",
                  "We're sorry to hear that you've encountered a bug on our platform. We're continuously working to improve it and we appreciate your patience during this phase. Could you kindly provide us with more details about the issue you've faced?"
                );
              }}
            >
              Report a bug
            </p>
            <p className="Footer-container-content__support__item">
              <Link to="/faq">FAQ</Link>
            </p>
          </div>
          <div className="Footer-container-content__followus">
            <p className="Footer-container-content__followus__title">
              Follow us
            </p>
            <div className="Footer-container-content__followus__icons">
              <div className="Footer-container-content__followus__icons__img">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100092435600745"
                >
                  <img src={Fb} alt="" />
                </a>
              </div>
              <div className="Footer-container-content__followus__icons__img">
                <a
                  target="_blank"
                  href="https://www.instagram.com/careerscoops/"
                >
                  <img src={Instagram} alt="" />
                </a>
              </div>
              <div className="Footer-container-content__followus__icons__img">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/career-scoops/?viewAsMember=true"
                >
                  <img src={Linkedin} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer-container-line" />
        <div className="Footer-container-copyright">
          <p>
            Copyright © 2024 Career Scoops™ | All Rights Reserved |{" "}
            <Link to="/terms">Terms and Conditions</Link> |{" "}
            <Link to="/privacypolicy">Privacy Policy</Link>
          </p>
        </div>
      </div>
      <InviteFriendModal
        isOpened={isModalOpened}
        closeModal={closeModal}
        text={text}
        title={title}
        leftBtnText="Cancel"
        rightBtnText="Send"
        img={Envelope}
        from="Home"
      />
      <Success
        isOpened={isSuccessModalOpened}
        close={closeSuccessModal}
        title="success"
        text="Check your Email"
        image={SuccessImage}
      />
    </footer>
  );
};

export default Footer;
