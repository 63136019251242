import React from "react";
import Plus from "../../../../assets/addImage.svg";
import { Link, useOutletContext } from "react-router-dom";
import ProfilePicModal from "../../../../modals/ProfilePicModal";
import Human from "../../../../assets/Human.svg";
import useProfileInfo from "../../../../hooks/useProfileInfo";
import ChangeAvatar from "../../../../assets/ChangeAvatarIcon.svg"
import classNames from "classnames";
import "./ProfileInfo.scss";

const ProfileInfo = () => {
  const { user } = useOutletContext()
  const {
    isProfilePicModalOpened,
    image,
    role,
    width,
    openPrfilePicModal,
    closeProfilePicModal,
    getImageFile,
    getImage
  } = useProfileInfo();
  return (
    <div className="ProfileInfoCont">
      <div className="ProfileInfoCont__passwordCont">
        <div className="ProfileInfoCont__passwordCont__content">
          <Link
            className="ProfileInfoCont__passwordCont__content__text"
            to="/profile/EditProfile"
          >
            Edit Profile Info
          </Link>
          <div className="ProfileInfoCont__passwordCont__content__verticalLane"></div>
          <Link
            className="ProfileInfoCont__passwordCont__content__text"
            to="/profile/editPassword"
          >
            Change Password
          </Link>
        </div>
      </div>
      <div className="ProfileInfoCont__userCont">
        <div className="ProfileInfoCont__userCont__identity">
          <div className="ProfileInfoCont__userCont__identity__imgWrapper">
            <img
              src={image ? image : Human}
              alt="userImg"
              className={classNames({
                mainImg: image
              })}

            />
            <img
              src={image ? ChangeAvatar : Plus}
              alt="plus"
              className="ProfileInfoCont__userCont__identity__imgWrapper__chnageAvatar"
              onClick={openPrfilePicModal}
            />
            <ProfilePicModal
              title={image ? "Change your profile pic" : "Add your profile pic"}
              image={image ? image : Human}
              icon={image ? ChangeAvatar : Plus}
              leftBtnText="Cancel"
              rightBtnText="Save"
              isOpened={isProfilePicModalOpened}
              close={closeProfilePicModal}
              getImageFile={getImageFile}
              getImage={getImage}
            />
          </div>
          <div className="ProfileInfoCont__userCont__identity__info">
            <p className="ProfileInfoCont__userCont__identity__info__name">{`${user?.first_name} ${user?.last_name}`}</p>
            <p className="ProfileInfoCont__userCont__identity__info__email">
              {user?.email}
            </p>
          </div>
        </div>
        {role !== 'Mentor' && <div className="ProfileInfoCont__userCont__additional">
          <p className="ProfileInfoCont__userCont__additional__key">Age:</p>
          <p className="ProfileInfoCont__userCont__additional__value">
            {user?.age ? user?.age : 'fill your age'}
          </p>
        </div>}
        <div className="ProfileInfoCont__userCont__additional">
          <p className="ProfileInfoCont__userCont__additional__key">Phone:</p>
          <p className="ProfileInfoCont__userCont__additional__value">
            {user?.phone}
          </p>
        </div>
        <div className={classNames("ProfileInfoCont__userCont__additional", {
          column: width <= 360
        })}>
          <p className="ProfileInfoCont__userCont__additional__key">Address:</p>
          <p className="ProfileInfoCont__userCont__additional__value">
            {user?.address}
          </p>
        </div>
        {role !== 'Mentor' ? <>
          <div className="ProfileInfoCont__userCont__additional">
            <p className="ProfileInfoCont__userCont__additional__key">Carrers:</p>
            <p className="ProfileInfoCont__userCont__additional__value">
              {user?.careers}
            </p>
          </div>
          <div className="ProfileInfoCont__userCont__additional">
            <p className="ProfileInfoCont__userCont__additional__key">Certainty:</p>
            <p className="ProfileInfoCont__userCont__additional__value">
              {user?.certainty}
            </p>
          </div>
        </> : <div className="ProfileInfoCont__userCont__additional">
          <p className="ProfileInfoCont__userCont__additional__key">About me:</p>
          <p className="ProfileInfoCont__userCont__additional__value">
            {user?.about_me}
          </p>
        </div>}
      </div>
    </div>
  );
};

export default ProfileInfo;
