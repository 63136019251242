import React from "react";
import { FirstForm, SecondForm } from "./components";
import { useMentorSignUp } from "../../hooks";
import Saly from "../../assets/Saly.png";
import Thank from '../../assets/ThankyouSignup.png'
import Success from "../../assets/success.png";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./MentorSignUp.scss";
import { ThankYouSignUp } from "../../modals";
import { useNavigate } from "react-router-dom";

const MentorSignUp = () => {
  const {
    addTags,
    isSuccessVisible,
    changeSuccessVisibility,
    tags,
    availableTags,
    isSaving,
    removeTag,
    page,
    setPage,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    phone,
    showError,
    setShowError,
    error,
    firstError,
    lastError,
    setPhone,
    setAddress,
    setZipCode,
    handleSignUp,
    industries,
    termsCheck,
    setTermsCheck,
    shareDataCheck,
    setShareDataCheck,
    handleSelectedIndustry,
    selectedIndustry,
    linkedIn,
    setLinkedIn,
    title,
    setTitle,
    getErrorFromBackend,
    closeErrorModal,
    isErroModalOpened,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    phoneError,
  } = useMentorSignUp();

  const navigate = useNavigate();
  const { width, isMobile } = useWindowWidth()
  return (
    <section className="MentorSignUp">
      <h3>Signing up as Commmunity Partner</h3>
      {page === 1 ? (
        <FirstForm
          firstName={firstName}
          setFirstName={setFirstName}
          email={email}
          setEmail={setEmail}
          lastName={lastName}
          setLastName={setLastName}
          phone={phone}
          setShowError={setShowError}
          showError={showError}
          error={error}
          firstError={firstError}
          lastError={lastError}
          setPhone={setPhone}
          setPage={setPage}
          addTags={addTags}
          tags={tags}
          availableTags={availableTags}
          removeTag={removeTag}
          industries={industries}
          handleSelectedIndustry={handleSelectedIndustry}
          selectedIndustry={selectedIndustry}
          linkedIn={linkedIn}
          setLinkedIn={setLinkedIn}
          title={title}
          setTitle={setTitle}
          phoneError={phoneError}
        />
      ) : (
        <SecondForm
          password={password}
          isSaving={isSaving}
          setPassword={setPassword}
          setCompleteAddress={setAddress}
          setZipCode={setZipCode}
          goBack={setPage}
          termsCheck={termsCheck}
          setTermsCheck={setTermsCheck}
          shareDataCheck={shareDataCheck}
          setShareDataCheck={setShareDataCheck}
          handleSignUp={handleSignUp}
          isErroModalOpened={isErroModalOpened}
          closeErrorModal={closeErrorModal}
          getErrorFromBackend={getErrorFromBackend}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
        />
      )}

      {width > 1221 && <img src={Saly} style={{ position: 'absolute', left: '30px', bottom: 0 }} />}

      <ThankYouSignUp
        isOpened={isSuccessVisible}
        close={() => {
          changeSuccessVisibility(false)
          navigate('/')
        }}
        image={Thank}
        title={'Success !'}
        EmailCheckText={'Thank you for your interest to become our Community Partner! Please verify your email to activate your account.'}
      />
    </section>
  );
};

export default MentorSignUp;