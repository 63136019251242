import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import {
  authIsAuthenticated,
  authUser,
} from "../redux/selectors/authSelectors";
// import {
//   MyFeedBack,
//   MyPlan,
//   ProfileInfo,
//   Sessions,
//   EditPassword,
//   EditProfile,
//   Recommendations,
// } from "../pages/Profile/components";
// import { Carrer } from "../pages/Profile/components/Recommendations/components";
import { UserSettings } from "../constants/AppConstants";
import { signOut } from "../redux/actions/authActions";
import { initMentors,initGetFeedBack, initGetSessions, initGetProfile, initGetRecommendation } from "../redux/actions";
import { initGetResults } from "../redux/actions/scenariosActions";

const useProfile = () => {
  const storageUser = useSelector((state) => authUser(state));
  const { role } = storageUser || {};
  const user = useSelector((state)=>(state?.profileReducer?.profile));
  const dispatch = useDispatch();
  // const contents = {
  //   profile: <Sessions />,
  //   sessions: <Sessions />,
  //   ProfileInfo: <ProfileInfo user={user} />,
  //   myPlan: <MyPlan />,
  //   MyFeedback: <MyFeedBack />,
  //   EditProfile: <EditProfile user={user} />,
  //   editPassword: <EditPassword />,
  //   recommendations:<Recommendations/>,
  //   carrer:<Carrer/>

  // };
  useEffect(()=>{
    dispatch(initMentors())
    dispatch(initGetFeedBack())
    dispatch(initGetSessions())
    dispatch(initGetResults())
    dispatch(initGetRecommendation())
  },[])
  const { pathname } = useLocation();
  const [settings, setSettings] = useState(role === "Mentor" ? UserSettings.mentor : UserSettings.student);
  const [isInviteFriendModalopened, setInviteFriendModalopened] =
    useState(false);
  const [isLogOutModalOpened, setLogOutModalOpened] = useState(false);
  const profileMatch = useMatch('/profile/*');
  const isProfileChildRoute = profileMatch !== null && profileMatch.params['*'] !== '';
  const handleSettingClick = (id, arr) => {
    const changedSettings = arr.map((el) => ({
      ...el,
      isActive: el.id === id,
    }));
    setSettings(changedSettings);
  };

  const closeInviteFriendModal = (e) => {
    // const changedSettings = settings.map((el) => ({
    //   ...el,
    //   isActive: el.route === pathname.split("/").pop(),
    // }))
    // i have commented the lines above because i have another option when user clicked invite friend the invite friend tab would be green and when closed invite friend green item would become the item that had been  green before ivite friend click
    setInviteFriendModalopened(false);
  };

  const openInviteFriendModal = () => {
    setInviteFriendModalopened(true);
  };

  const openLogOutModal = () => {
    setLogOutModalOpened(true);
  };
  const closeLogoutModal = () => {
    setLogOutModalOpened(false);
  };
  const handleLogOut = () => {
    dispatch(signOut());
  };

  const isAuth = useSelector((state) => authIsAuthenticated(state));
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, [isAuth, navigate]);

  useEffect(() => {
    const changedSettings = settings.map((el) => ({
      ...el,
      isActive:
        (el.route === "sessions" &&
          (pathname.split("/").pop() === "profile" ||
            pathname.split("/").pop() === "sessions")) ||
        el.route === pathname.split("/").pop(),
    }));
    const activeEl = changedSettings.find((el) => el.isActive === true);
    if (activeEl === undefined) {
      if (
        pathname.split("/").pop() === "EditProfile" ||
        pathname.split("/").pop() === "editPassword"
      ) {
        const changedSettings = settings.map((el) => ({
          ...el,
          isActive: el.department === "Profile Info",
        }));
        setSettings(changedSettings);
      } else if (pathname.includes('carrer')) {
        const changedSettings = settings.map((el) => ({
          ...el,
          isActive: el.department === "Recommendations",
        }));
        setSettings(changedSettings);
      } else {
        setSettings(settings);
      }
    } else {
      setSettings(changedSettings);
    }
    setInviteFriendModalopened(false);
    setLogOutModalOpened(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return {
    user,
    pathname,
    // contents,
    settings,
    isInviteFriendModalopened,
    isLogOutModalOpened,
    isProfileChildRoute,
    handleSettingClick,
    handleLogOut,
    closeInviteFriendModal,
    openInviteFriendModal,
    openLogOutModal,
    closeLogoutModal,
  };
};

export default useProfile;
