import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { TagInfo, TextModal } from "../../../../modals";
import "./Questions.scss";
import classNames from "classnames";
import { useRef } from "react";
const DragableCups = ({
  image,
  text,
  id,
  itemIsDropped,
  openModal,
  closeModal,
  selectedId,
  info,
}) => {
  const newId = selectedId
  const [{ isDragging }, drag, preview] = useDrag(() => {
    return {
      type: "tag",
      item: { id, text, image },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    };
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);


  return !itemIsDropped ? (
    <div
      className="Sceanrio4__question13__cupImgs__imgWrapper"
      onClick={() => {
        openModal(id);
      }}
    >
      <img
        src={image}
        alt="cupImage"
        className={classNames(
          "Sceanrio4__question13__cupImgs__imgWrapper__img close__popup__hover",
          {
            selected: selectedId === id,
          }
        )}
        style={
          isDragging
            ? {
                opacity: 0.3,
              }
            : null
        }
        ref={drag}
      />
      <p className="Sceanrio4__question13__cupImgs__imgWrapper__imgText">
        {text}
      </p>
      <TagInfo
        info={info?.description}
        isOpened={selectedId === id}
        closeModal={closeModal}
        isbtnVisible={false}
        selectedId={selectedId}
      />
    </div>
  ) : (
    <div className="Sceanrio4__question13__cupImgs__imgWrapper">
      <img
        src={image}
        alt="cupImage"
        className="Sceanrio4__question13__cupImgs__imgWrapper__img dropped"
      />
      <p className="Sceanrio4__question13__cupImgs__imgWrapper__imgText">
        {text}
      </p>
    </div>
  );
};

export default DragableCups;
