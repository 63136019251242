import React from "react";
import AiRobot from "../../../../../../assets/AiRobot.png";
import "./AiThinking.scss";
import useThinking from "../../../../../../hooks/useThinking";

const AiThinking = () => {
  const { text } = useThinking();
  return (
    <div className="AiThinking">
      <div className="AiThinking__imgWrapper">
        <img
          src={AiRobot}
          alt="AiRobot"
          className="AiThinking__imgWrapper__img"
        />
      </div>
      <div className="AiThinking__textsWrapper">
        <p className="AiThinking__textsWrapper__yellowText">
          Our engine calculates your future career fit…
        </p>
        <p className="AiThinking__textsWrapper__SecondText">{text}</p>
      </div>
    </div>
  );
};

export default AiThinking;
