import React from "react";
import DragableCups from "./DragableCups";
import DropableCups from "./DropableCups";
import ScenarioFooter from "../../../../components/ScenarioFooter/ScenarioFooter";

import "./Questions.scss";
import useQuestion13 from "../../../../hooks/useQuestion13";
const Question13 = ({ handleSubmitResults, data }) => {
  const {
    items,
    itemImages,
    dashBoardItems,
    nextDisabled,
    selectedId,
    addItemTodashBoard,
    openModal,
    closeModal,
  } = useQuestion13(data);
  return (
    <section className="Sceanrio4">
      <div className="Sceanrio4__question13">
        <p className="Sceanrio4__question13__title">SCENARIO 4</p>
        <p className="Sceanrio4__question13__introText">{data.question}</p>
        <div className="Sceanrio4__question13__cupImgs">
          {items.map((el) => (
            <DragableCups
              key={el.id}
              image={itemImages.filter(e => e.id === el.id)[0].image}
              text={el.text}
              id={el.id}
              itemIsDropped={el.isDroped}
              openModal={openModal}
              closeModal={closeModal}
              selectedId={selectedId}
              info={items.find((element)=>(selectedId===element.id))}
            />
          ))}
        </div>
        <div className="Sceanrio4__question13__emptyBoards">
          {[1, 2, 3].map((el, ind) => (
            <DropableCups
              key={ind}
              itemImages={itemImages}
              text={`My top ${el}`}
              addItemTodashBoard={addItemTodashBoard}
              data={dashBoardItems[ind + 1]}
              index={ind}
            />
          ))}
        </div>
      </div>
      <ScenarioFooter
        variation="question"
        handleSubmitResults={handleSubmitResults}
        buttons={{
          next: {
            highLightColor: false,
            disabled: nextDisabled,
            text: "NEXT ",
          },
          back: { text: "PREVIOUS SCENARIO" },
        }}
      />
    </section>
  );
};

export default Question13;
