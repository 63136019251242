import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useEditPassword = () => {
  const [passwordObj, setPasswordObj] = useState({
    password: "",
    maskedPassword: "",
    isEyeClosed: true,
  });
  const [confirmPasswordObj, setConfirmPasswordObj] = useState({
    password: "",
    maskedPassword: "",
    isEyeClosed: true,
  });
  const navigate = useNavigate();
  const [isCloseModalOpened, setCloseMOdalIsOPened] = useState(false);
  const [confirmationError, setConfirmationError] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (e.target.value.length < passwordObj.password.length) {
      let newPassword = passwordObj.password.slice(0, -1);
      setPasswordObj({
        ...passwordObj,
        password: newPassword,
        maskedPassword: "*".repeat(value.length),
      });
    } else {
      let inpcahracters = "";
      const lastCharacter = value.charAt(value.length - 1);
      inpcahracters = passwordObj.password + lastCharacter;
      setPasswordObj({
        ...passwordObj,
        password: inpcahracters,
        maskedPassword: "*".repeat(value.length),
      });
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    if (e.target.value.length < confirmPasswordObj.password.length) {
      let newPassword = confirmPasswordObj.password.slice(0, -1);
      setConfirmPasswordObj({
        ...confirmPasswordObj,
        password: newPassword,
        maskedPassword: "*".repeat(value.length),
      });
    } else {
      let inpcahracters = "";
      const lastCharacter = value.charAt(value.length - 1);
      inpcahracters = confirmPasswordObj.password + lastCharacter;
      setConfirmPasswordObj({
        ...confirmPasswordObj,
        password: inpcahracters,
        maskedPassword: "*".repeat(value.length),
      });
    }
  };
  const handlePasswordEye = () => {
    setPasswordObj({
      ...passwordObj,
      isEyeClosed: !passwordObj.isEyeClosed,
    });
  };
  const handleConfirPasswordEye = () => {
    setConfirmPasswordObj({
      ...confirmPasswordObj,
      isEyeClosed: !confirmPasswordObj.isEyeClosed,
    });
  };
  const openCloseModal = () => {
    setCloseMOdalIsOPened(true);
  };

  const closeCloseModal = () => {
    setCloseMOdalIsOPened(false);
  };
  useEffect(() => {
    if (passwordObj.password === confirmPasswordObj.password) {
      setIsErrorVisible(false);
      setConfirmationError("");
    }
  }, [passwordObj, confirmPasswordObj]);

  const checkPasword = () => {
    if (passwordObj.password === confirmPasswordObj.password) {
      setIsErrorVisible(false);
      setConfirmationError("");
      navigate("/profile/ProfileInfo");
    } else {
      setIsErrorVisible(true);
      setConfirmationError("check Confirmation password");
    }
  };
  const loseChanges = () => {
    setPasswordObj({
      password: "",
      maskedPassword: "",
      isEyeClosed: true,
    });
    setConfirmPasswordObj({
      password: "",
      maskedPassword: "",
      isEyeClosed: true,
    });
    setCloseMOdalIsOPened(false)
  };
  return {
    passwordObj,
    confirmPasswordObj,
    isCloseModalOpened,
    handleConfirmPasswordChange,
    handlePasswordChange,
    handlePasswordEye,
    handleConfirPasswordEye,
    openCloseModal,
    closeCloseModal,
    checkPasword,
    isErrorVisible,
    confirmationError,
    loseChanges
  };
};

export default useEditPassword;
