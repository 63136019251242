import React, { useState } from "react";
import Apostrophe from "../../../../assets/Apostrophe.svg"
import { partnersFeedback } from "../../../../constants/AppConstants";

import "./Feedback.scss";

const Feedback = () => {
  const [content, setContent] = useState(partnersFeedback);

  const handleItemClick = (id) => {
    const newContent = content.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isOpen: true,
        };
      }
      return {
        ...item,
        isOpen: false,
      };
    });
    setContent(newContent);
  };

  return (
    <section className="Feedback">
      <p>Know more about us from our community supporters</p>
      <div className="Feedback-container">
        {content.map(({ id, img, name, isOpen, position, feedBack }) => (
          <div onClick={() => handleItemClick(id)} key={id} className={`item${isOpen ? " active" : ""}`}>
            <div className="item-author">
              <img src={img} alt="" />
              <p>{name}</p>
            </div>
            {isOpen && (
              <div className="item-content">
                <div className="item-content__title">
                  <p>{position}</p>
                  <img src={Apostrophe} alt="Apostrophe"/>
                </div>
                <p className="item-content__text">{feedBack}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Feedback;
