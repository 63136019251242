import React from "react";
import closeIcon from "../../assets/Close.svg";
import "./ThankYouSignUp.scss";

const ThankYouSignUp = ({ isOpened, image, title, EmailCheckText, close }) => {
  return isOpened ? (
    <div className="container">
      <div className="ThankYouSignUp-cont" onClick={close}>
        <div
          className="ThankYouSignUp-cont__content"
          onClick={(e) => e.stopPropagation()}
        >
          <img
            src={closeIcon}
            alt="closeIcon"
            className="ThankYouSignUp-cont__content__imgClose"
            onClick={close}
          />
          <div className="ThankYouSignUp-cont__content__imgWrapper">
            <img
              src={image}
              alt="heart"
              className="ThankYouSignUp-cont__content__imgWrapper__img"
            />
          </div>
          <div className="ThankYouSignUp-cont__content__textCont">
            <p className="ThankYouSignUp-cont__content__textCont__firstText">
              {title}
            </p>
            <div className="ThankYouSignUp-cont__content__textCont__SecondText">
              {EmailCheckText}
            </div>
          </div>
          <button className="ThankYouSignUp-cont__btnCont" onClick={close}>
            <p className="ThankYouSignUp-cont__btnCont__text">OK</p>
          </button>
        </div>
      </div>
    </div> 
  ) : null;
};

export default ThankYouSignUp;
