import React from "react";
import { months } from "../constants/AppConstants";
import { decideTime } from "../common/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useDateHighToLow = () => {
  const monthsarray = months;
  const navigate = useNavigate();
  const mentorSessions = useSelector(
    (state) => state?.sessionReducer?.sessions
  );
  const mentors = useSelector((state) => state?.mentorReducer?.mentor?.results);
  const findeMentor = (sessionId) => {
    const mentorId = mentorSessions.find((el) => el.id === sessionId)?.mentor;
    if (mentorId) {
      const mentor = mentors.find((el) => el.id === mentorId);
      return mentor?.first_name;
    }
  };
  const devideColumns = (data) => {
    const columns = [[], [], []];
    data?.forEach((el, index) => {
      const columnItem = (
        <div className="DateHighToLow-cont__column__items" key={el.id}>
          <div className="DateHighToLow-cont__column__items__dateCont">
            <p className="DateHighToLow-cont__column__items__dateCont__date">{`${new Date(
              el.created_at
            ).getDate()} ${
              monthsarray[new Date(el.created_at).getMonth()]
            } ${new Date(el.created_at).getFullYear()}`}</p>
            {/* <p className="DateHighToLow-cont__column__items__dateCont__duration">
                {decideTime(el.duaration)}
              </p> */}
          </div>
          <div className="DateHighToLow-cont__column__items__info">
            <div className="DateHighToLow-cont__column__items__info__topicCont">
              <p className="DateHighToLow-cont__column__items__info__topicCont__topic">
                Topic:
              </p>
              <p className="DateHighToLow-cont__column__items__info__topicCont__subject">
                {el.topic}
              </p>
            </div>
            <div className="DateHighToLow-cont__column__items__info__mentorCont">
              <p className="DateHighToLow-cont__column__items__info__mentorCont__mentor">
                Mentor:
              </p>
              <p className="DateHighToLow-cont__column__items__info__mentorCont__mentorsName">
                {findeMentor(el.mentor_session)}
              </p>
            </div>
            <div className="DateHighToLow-cont__column__items__info__lane"></div>
            <p className="DateHighToLow-cont__column__items__info__text">
              {el.message}
            </p>
          </div>
        </div>
      );
      const columnIdx = index % 3;
      columns[columnIdx].push(columnItem);
    });
    return columns;
  };
  return {
    devideColumns,
    navigate,
  };
};
export default useDateHighToLow;
