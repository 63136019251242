import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initSetProfile } from "../redux/actions";
import { authUser } from "../redux/selectors/authSelectors";
import { useNavigate } from "react-router-dom";

const useEditProfile = (user) => {
  const storageUser = useSelector(authUser);
  const addressRef = useRef(null);
  const navigate = useNavigate()
  const { role } = storageUser || {};
  const [fullName, setFullName] = useState(
    user?.first_name || user?.last_name
      ? `${user?.first_name} ${user?.last_name}`
      : ""
  );
  const ages = [
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33,
    34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
    53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
    72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
    91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
  ];
  const [address, setAddress] = useState(user?.address ? user.address : "");
  const [zipcode, setZipCode] = useState(user?.zipcode ? user.zipcode : "");
  const [careers, setCareers] = useState(user?.careers ? user.careers : "");
  const [certainty, setCertainty] = useState(user?.certainty ? user.certainty : "");
  const [aboutMe, handleAboutMe] = useState(user?.about_me || '')
  const [age, setAge] = useState(user?.age ? user.age : 0);
  const [email, setEmail] = useState(user?.email ? user.email : "");
  const [phone, setPhone] = useState(user?.phone ? user.phone : "");
  const [isCloseModalOpened, setCloseMOdalIsOPened] = useState(false);
  const [isAgeListOpened, setIsageListOpened] = useState(false);
  const [city, setCity] = useState(user?.city ? user.city : "");
  const [country, setCountry] = useState(user?.country ? user.country : "");
  const [state, setState] = useState(user?.state ? user.state : "");
  const dispatch = useDispatch();
  const countryRef = useRef(null);
  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressRef.current,
      { types: ["geocode"] }
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      setAddress(place.formatted_address);

      const addressComponents = place.address_components;
      for (let i = 0; i < addressComponents.length; i++) {
        const addressType = addressComponents[i].types[0];
        if (addressType === "locality") {
          setCity(addressComponents[i].long_name);
        } else if (addressType === "postal_code") {
          setZipCode(addressComponents[i].long_name);
        } else if (addressType === "administrative_area_level_1") {
          setState(addressComponents[i].long_name);
        } else if (addressType === "country") {
          setCountry(addressComponents[i].long_name);
        }
      }
    });
  }, []);

  const handleCountry = (e) => {
    setCountry(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
    // const autocomplete = new window.google.maps.places.Autocomplete(
    //   addressRef.current,
    //   { types: ["geocode"] }
    // );
    // autocomplete.addListener("place_changed", () => {

    //   const place = autocomplete.getPlace();
    //   setAddress(place.formatted_address);

    //   const addressComponents = place.address_components;
    //   for (let i = 0; i < addressComponents.length; i++) {
    //     const addressType = addressComponents[i].types[0];
    //     if (addressType === "locality") {
    //       setCity(addressComponents[i].long_name);
    //     } else if (addressType === "postal_code") {
    //       console.log(addressComponents[i].long_name, " zip code");
    //       setZipCode(addressComponents[i].long_name);
    //     } else if (addressType === "administrative_area_level_1") {
    //       setState(addressComponents[i].long_name);
    //     } else if (addressType === "country") {
    //           setCountry(addressComponents[i].long_name)
    //     }
    //   }
    // });
  };
  const openCloseModal = () => {
    setCloseMOdalIsOPened(true);
  };

  const closeCloseModal = () => {
    setCloseMOdalIsOPened(false);
  };
  const handleFullName = (e) => {
    setFullName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const handleZipCode = (e) => {
    setZipCode(e.target.value);
  };
  const handleState = (e) => {
    setState(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const updateProfile = () => {
    dispatch(
      initSetProfile(role === 'Student' ? {
        ...user,
        address,
        email,
        age,
        certainty,
        careers,
        zipcode,
        phone,
        state,
        country,
        city,
        first_name: fullName.split(" ")[0],
        last_name: fullName.split(" ")[1],
      } : {
        ...user,
        address,
        email,
        zipcode,
        phone,
        state,
        country,
        city,
        about_me: aboutMe,
        first_name: fullName.split(" ")[0],
        last_name: fullName.split(" ")[1],
      })
    );
    navigate("/profile/ProfileInfo")
  };
  const openAgeList = () => {
    setIsageListOpened(true);
  };
  const CloseAgeList = () => {
    setIsageListOpened(false);
  };
  const removeChanges = () => {
    setEmail("");
    setAge("");
    setFullName("");
    setPhone("");
    setZipCode("");
    setAddress("");
    setCountry("");
    setState("");
    setCity("");
    setCloseMOdalIsOPened(false);
    navigate("/profile/ProfileInfo")
  };
  const handleAge = (num) => {
    setAge(Number(num));
    CloseAgeList();
  };
  return {
    fullName,
    isCloseModalOpened,
    age,
    email,
    phone,
    zipcode,
    address,
    isAgeListOpened,
    ages,
    role,
    addressRef,
    countryRef,
    country,
    state,
    city,
    aboutMe,
    handleAboutMe,
    closeCloseModal,
    openCloseModal,
    updateProfile,
    removeChanges,
    handleFullName,
    handleEmail,
    handlePhone,
    handleZipCode,
    handleAddress,
    openAgeList,
    CloseAgeList,
    handleAge,
    handleCountry,
    handleState,
    handleCity,
    careers,
    setCareers,
    certainty,
    setCertainty
  };
};

export default useEditProfile;
