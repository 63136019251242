import React from "react";
import { useSignUpMentorFirst } from "../../../../hooks";
import Info from "../../../../assets/Info.svg";
import Close from "../../../../assets/Close.svg";
import Error from "../../../../assets/error.svg";
import "./FirstForm.scss";
import classNames from "classnames";

const FirstForm = ({
  setPage,
  firstName,
  setFirstName,
  email,
  setEmail,
  addTags,
  tags,
  availableTags,
  removeTag,
  lastName,
  error,
  firstError,
  phoneError,
  lastError,
  showError,
  setShowError,
  setLastName,
  phone,
  setPhone,
  industries,
  handleSelectedIndustry,
  selectedIndustry,
  linkedIn,
  setLinkedIn,
  title,
  setTitle,
}) => {
  const { handleBack } = useSignUpMentorFirst();

  return (
    <div className="FirstForms">
      <div className="FirstForms-cont">
        <div className="FirstForms-cont__1">
          <div
            className={classNames("input", {
              marginBottomFirst: showError && !firstError && lastError,
            })}
          >
            <p>First Name</p>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {firstError && showError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>Please enter valid first name</p>
              </div>
            )}
          </div>

          <div className="input">
            <p>Title</p>
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>

          <div className="input">
            <p>Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && showError && (
              <div className="FirstForms__error">
                <img src={Error} alt="" />
                <p>{error}</p>
              </div>
            )}
          </div>
        </div>
        <div className="FirstForms-cont__2">
          <div
            className={classNames("input", {
              marginBottom: showError && firstError && !lastError,
            })}
          >
            <p>Last Name</p>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {lastError && showError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>Please enter valid last name</p>
              </div>
            )}
          </div>
          <div className="input">
            <p>Phone</p>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {phoneError && showError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>Please enter valid phone</p>
              </div>
            )}
            
          </div>
          <div className="input">
            <p>Industry</p>
            <select
              onChange={(e) => {
                handleSelectedIndustry(e.target.value);
              }}
              value={selectedIndustry?.text}
            >
              {industries?.map((el) => (
                <option value={el.name} id={el.id} key={el.id}>
                  {el.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="FirstForms__tag">
        <p>Add tags related on your profession/background</p>
        {/* <input
          placeholder="Press Enter to add tags"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={addTags}
        /> */}
        <select onChange={addTags}>
          <option value={null}>Choose</option>
          {availableTags
            .filter((item) => !tags.some((i) => i === item.id))
            .map((tag) => (
              <option key={tag.id} value={tag.id}>
                {tag.name}
              </option>
            ))}
        </select>
        <div className="FirstForms__tag__container">
          {tags.map((tag, idx) => (
            <div onClick={() => removeTag(idx)} key={idx}>
              <p>{availableTags.filter((t) => t.id === tag).shift()?.name}</p>
              <img src={Close} alt="" />
            </div>
          ))}
        </div>
      </div>
      <div className="FirstForms__tag">
        <p>Provide LinkedIn or other profile page</p>
        <input
          type="text"
          value={linkedIn}
          onChange={(e) => {
            setLinkedIn(e.target.value);
          }}
        />
      </div>
      <div className="FirstForms-navigation">
        <button onClick={handleBack} className="FirstForms-navigation__back">
          Back
        </button>
        <button
          onClick={() => {
            if (error || !firstName || !lastName || !phone) {
              setShowError(true);
            } else {
              setPage(2);
            }
          }}
          className="FirstForms-navigation__continue"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default FirstForm;
