import React from "react";
import { Settings } from "./components";
import { useProfile } from "../../hooks";
import { InviteFriendModal } from "../../modals";
import Envelope from "../../assets/InviteFriendEnvelope.png";
import classNames from "classnames";
import "./Profile.scss";
import { Outlet } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth";

const Profile = () => {
  const {
    user,
    pathname,
    settings,
    isInviteFriendModalopened,
    isLogOutModalOpened,
    isProfileChildRoute,
    handleSettingClick,
    handleLogOut,
    closeInviteFriendModal,
    openInviteFriendModal,
    openLogOutModal,
    closeLogoutModal,
  } = useProfile();
  const { width, isMobile } = useWindowWidth()
  return (
    <>
      <div
        className={classNames("profileCont", {
          bigHeight: isMobile && pathname !== "/profile"
        })}
      >
        <InviteFriendModal
          isOpened={isInviteFriendModalopened}
          img={Envelope}
          title="Help our Mission: Invite a Friend"
          text=""
          leftBtnText="Cancel"
          rightBtnText="Send"
          closeModal={closeInviteFriendModal}
        />
        <div
          className={classNames("profileCont__settingsCont", {
            hide: (isMobile && isProfileChildRoute),
          })}
        >
          <Settings
            settings={settings}
            handleSettingClick={handleSettingClick}
            name={user?.first_name}
            handleLogOut={handleLogOut}
            surname={user?.last_name}
            openInviteFriendModal={openInviteFriendModal}
            openLogOutModal={openLogOutModal}
            closeLogoutModal={closeLogoutModal}
            isLogOutModalOpened={isLogOutModalOpened}
            isMobile={isMobile}
          />
        </div>
        <div
          className={classNames("profileCont__contentCont", {
            hide: (isMobile && !isProfileChildRoute)
          })}
        >
          {/* {contents[pathname.split("/").pop()]} */}
          <Outlet context={{
            user,
          }} />
        </div>
      </div>
    </>
  );
};

export default Profile;
