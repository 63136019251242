import React from "react";
import arrowLeft from "../../../../assets/grayArrowLeft.svg";
import "./Carrer.scss";
import classNames from "classnames";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import useCarrer from "../../../../hooks/useCarrer";
import { BookModal, Success } from "../../../../modals";
import SuccessImage from "../../../../assets/success.png";

const Carrer = () => {
  const {
    navigateBack,
    state,
    isSuccessModalOpened,
    closeSuccessModal,
    chosenItem,
    handleBack,
    handlescore,
    handleBookValidation,
  } = useCarrer();
  const { isMobile } = useWindowWidth();
  return (
    <div className="CarrerCont">
     <div className="infoCont">
     <div className="CarrerCont__header">
        <div
          onClick={() => {
            navigateBack()
          }}
          className={classNames("CarrerCont__header__iconWrapper", {
            hide: isMobile,
          })}
        >
          <img
            src={arrowLeft}
            alt=""
            className="CarrerCont__header__iconWrapper__icon"
          />
        </div>
        <div className="CarrerCont__header__textCont">
          <p
            className={classNames("CarrerCont__header__textCont__Profecy", {
              hide: isMobile,
            })}
          >
            {state.text}
          </p>
          <p className="CarrerCont__header__textCont__mentor">
            <span className="CarrerCont__header__textCont__mentor__key">
              Mentor:{" "}
            </span>
            {state.first_name}
          </p>
        </div>
      </div>
      <p className="CarrerCont__decription">
        {`${state.description_long}`}
      </p>
     </div>

      <BookModal
        handlescore={handlescore}
        chosenItem={chosenItem}
        handleBook={() => handleBookValidation(state.id)}
        text={state?.description}
        isContentType={true}
        handleBack={() => handleBack(state.id)}
      />
      <Success
        image={SuccessImage}
        title="Success !"
        text="Thank you for letting us know you want to learn more! We'll take it from here based on your Career Scoops plan."
        isOpened={isSuccessModalOpened}
        close={closeSuccessModal}
      />
    </div>
  );
};

export default Carrer;
