import React from "react";
import "./Questions.scss";
import { useDrop } from "react-dnd";
const DropableCups = ({ text, addItemTodashBoard, data, index, itemImages }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "tag",
    drop: (item, monitor) => {
      return addItemTodashBoard(item.id, monitor);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  }));
  return (
    <div className="Sceanrio4__question13__emptyBoards__boardWrapper" >
      <div className="Sceanrio4__question13__emptyBoards__boardWrapper__board" ref={drop}>
        {Object.keys(data).length ? <img src={itemImages.filter(el=> el.id === data.id)[0].image} alt="CUP" /> : null}
      </div>
      <p className="Sceanrio4__question13__emptyBoards__boardWrapper__board__text">{text}</p>
    </div>
  )
}
export default DropableCups;