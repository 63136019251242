import React from "react";
import "./DateLowToHigh.scss";
import useDateLowToHigh from "../../../../../../hooks/useDateLowToHigh";

const DateLowToHigh = ({ data, role }) => {
  const { devideColumns, navigate } = useDateLowToHigh();
  return (
    <div className="DateLowToHigh-cont">
      {data && data.length ? (
        devideColumns(data).map((el) => {
          return (
            <div className="DateLowToHigh-cont__column" key={Math.random()}>
              {el}
            </div>
          );
        })
      ) : (
        <div className="emptyMyFeedback-cont">
          <p className="emptyMyFeedback-cont__text">
            You don't have any reviews yet
          </p>
          {role === "Mentor" ? null : (
            <button
              className="emptyMyFeedback-cont__btn"
              onClick={() => {
                navigate("/scenario/0");
              }}
            >
              <p className="emptyMyFeedback-cont__btn__text">Start Test Now</p>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DateLowToHigh;
