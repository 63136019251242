import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useRecommendations from "../../../../hooks/useRecommendations";
import { Stars } from "./components";
import "./Recommendations.scss";
import Error from "../../../../assets/error.svg";

import { Success } from "../../../../modals";
const Recommendations = () => {
  const navigate = useNavigate();
  const { results, isLoading, isErrorModalOpened } = useRecommendations();
  return (
    <>
      {results?.length ? (
        <div className="Recommendations-cont">
          {results?.map((el) => {
            return (
              <div className="Recommendations-cont__item" key={el.id}>
                <div className="Recommendations-cont__item__topicCont">
                  <p className="Recommendations-cont__item__topicCont__key">
                    Topic:
                  </p>
                  <p className="Recommendations-cont__item__topicCont__value">
                    {el.text}
                  </p>
                </div>
                {/* <div className="Recommendations-cont__item__mentorCont">
              <p className="Recommendations-cont__item__mentorCont__key">
                Mentor:
              </p>
              <p className="Recommendations-cont__item__mentorCont__value">
                {el.first_name}
              </p>
            </div> */}
                <div className="Recommendations-cont__item__lane"></div>
                {
                  <div className="Recommendations-cont__item__StarandRateCont">
                    <Stars
                      arr={[1, 2, 3, 4, 5]}
                      // onChange={(newRating) => handleRatingChange(el.id, newRating)}
                      // rating={mentorRates ? mentorRates[el.id] : ""}
                      rate={el.score}
                    />
                    <p className="Recommendations-cont__item__StarandRateCont__rate">
                      {el?.score ? el.score : null}
                    </p>
                  </div>
                }
                <button
                  className="Recommendations-cont__item__btn"
                  onClick={() => {
                    navigate(`/profile/recommendations/carrer/${el.id}`, {
                      state: el,
                    });
                  }}
                >
                  <p className="Recommendations-cont__item__btn__text">View</p>
                </button>
              </div>
            );
          })}
        </div>
      ) : isLoading ? (
        <></>
      ) : (
        <>
          <div className="emptyMyFeedback-cont">
            <p className="emptyMyFeedback-cont__text">
              You don't have any recommendations yet
            </p>
            <button className="emptyMyFeedback-cont__btn">
              <Link to="/scenario/0" className="emptyMyFeedback-cont__btn__text">
                Start Test Now
              </Link>
            </button>
          </div>
          <Success
            image={Error}
            title=""
            text={isErrorModalOpened}
            isOpened={isErrorModalOpened}
            close={() => {
              navigate('/pricingplan')
            }}
          />
        </>
      )}
      <Outlet />
    </>
  );
};

export default Recommendations;
