import { useEffect, useState } from "react";

const useThinking = () => {
  const [index, setindex] = useState(0);
  const texts = [
    `Dwayne "The Rock" Johnson, before becoming a professional wrestler and
    actor, had dreams of playing professional football. However, after a
    number of injuries and being cut from the Canadian Football League, he
    had to reevaluate his career path and eventually found success in the
    entertainment industry.`,
    `Ellen DeGeneres, one of the most successful comedians and talk show hosts of our time, actually started her career as an administrative assistant. It wasn't until she began doing stand-up comedy on the side that she realized her true passion and started pursuing it full-time.`,
    `Arnold Schwarzenegger, the bodybuilder turned actor turned politician, originally wanted to be a soccer player. However, after watching a bodybuilding competition, he became obsessed with the sport and eventually became a seven-time Mr. Olympia champion. He then went on to pursue a successful career in acting and later became the Governor of California.`
  ]

  const text = texts[index % 3]
  useEffect(() => {
    const timer = setTimeout(() => setindex(old => old + 1), 10000)
    return () => clearTimeout(timer)
  }, [index])
  return {
    text
  }
}

export default useThinking;