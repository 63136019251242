import { useEffect, useRef, useState } from "react";
import useWindowWidth from "./useWindowWidth";
import { useNavigate } from "react-router-dom";

const useUpdateImage = () => {
  const { width, isMobile } = useWindowWidth();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [choosenImage, setChoosenImage] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const [isModalOpened, setIsMOdalOpened] = useState(false);
  const openModal = () => {
    setIsMOdalOpened(true);
  };
  const closeModal = () => {
    setIsMOdalOpened(false);
  };
  const handleFileInputChange = (e) => {
    const file = e.target?.files[0];
    if (file) {
      const url = window.URL.createObjectURL(file);
      setChoosenImage(url);
      setImageFile(file);
    }
  };
  useEffect(() => {
    if (!isMobile) {
      navigate("/profile/ProfileInfo");
    }
  }, [width]);
  const loseChanges = () => {
    setImageFile(null);
    setChoosenImage("");
    setIsMOdalOpened(false);
  };
  const saveChanges = () => {
    navigate("/profile/ProfileInfo");
  };
  return {
    fileInputRef,
    choosenImage,
    imageFile,
    isModalOpened,
    loseChanges,
    openModal,
    closeModal,
    handleIconClick,
    handleFileInputChange,
    saveChanges,
  };
};

export default useUpdateImage;
