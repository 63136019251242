import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../common/api/request";

const useForgetPassword = () => {
  const [isSuccessModalOpened,setIsSuccessModalOpened] = useState(false)
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showError, setShowError] = useState(false);
  const [confirmationError, setConfirmationError] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [passwordObj, setPasswordObj] = useState({
    password: "",
    maskedPassword: "",
    isEyeClosed: true,
  });
  const [confirmPasswordObj, setConfirmPasswordObj] = useState({
    password: "",
    maskedPassword: "",
    isEyeClosed: true,
  });
  const [errorFromBackend,setErrorFromBackend] = useState(null)
  const [isErrorModalOpened,setIsErrorModalOpened] = useState(false)
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const checkEmail = (e) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e)) {
      setEmailError("Please enter a valid email address.");
      setShowError(true);
    } else {
      setEmailError(null);
      setShowError(false);
      // setStep(2);
      sendEmailForReset()
    }
  };
  const sendEmailForReset = () => {
    axiosClient
    .post("password_reset/", {
      email,
    })
    .then((data) => {
     setIsSuccessModalOpened(true)
    })
    .catch((error) => {
      if(error?.response?.data?.status === "error" && typeof error?.response?.data === "object" && typeof error?.response?.data !== null){
        setErrorFromBackend(error?.response?.data.msg)
      }
      setIsSuccessModalOpened(false)
    });
  }
  const sendEmail = (e) => {
    checkEmail(e);
  };
  const backFromResetPassword = () => {
    setStep(1);
  };
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (e.target.value.length < passwordObj.password.length) {
      let newPassword = passwordObj.password.slice(0, -1);
      setPasswordObj({
        ...passwordObj,
        password: newPassword,
        maskedPassword: "*".repeat(value.length),
      });
    } else {
      let inpcahracters = "";
      const lastCharacter = value.charAt(value.length - 1);
      inpcahracters = passwordObj.password + lastCharacter;
      setPasswordObj({
        ...passwordObj,
        password: inpcahracters,
        maskedPassword: "*".repeat(value.length),
      });
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    if (e.target.value.length < confirmPasswordObj.password.length) {
      let newPassword = confirmPasswordObj.password.slice(0, -1);
      setConfirmPasswordObj({
        ...confirmPasswordObj,
        password: newPassword,
        maskedPassword: "*".repeat(value.length),
      });
    } else {
      let inpcahracters = "";
      const lastCharacter = value.charAt(value.length - 1);
      inpcahracters = confirmPasswordObj.password + lastCharacter;
      setConfirmPasswordObj({
        ...confirmPasswordObj,
        password: inpcahracters,
        maskedPassword: "*".repeat(value.length),
      });
    }
  };
  const handlePasswordEye = () => {
    setPasswordObj({
      ...passwordObj,
      isEyeClosed: !passwordObj.isEyeClosed,
    });
  };
  const handleConfirPasswordEye = () => {
    setConfirmPasswordObj({
      ...confirmPasswordObj,
      isEyeClosed: !confirmPasswordObj.isEyeClosed,
    });
  };
  useEffect(() => {
    if (passwordObj.password === confirmPasswordObj.password) {
      setIsErrorVisible(false);
      setConfirmationError("");
    }
  }, [passwordObj, confirmPasswordObj]);
  const ResetPassword = () => {
    if (passwordObj.password === confirmPasswordObj.password) {
      setIsErrorVisible(false);
      setConfirmationError("");
      navigate('/')
    } else {
      setIsErrorVisible(true);
      setConfirmationError("check Confirmation password");
    }
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpened(false)
    navigate("/")
  }

  useEffect(()=>{
    if(errorFromBackend){
      setIsErrorModalOpened(true)
    }
  },[errorFromBackend])

   useEffect(() => {
     if(emailError){
      setEmailError("")
      setShowError(false)
     }
   },[email])

  const closeErrorModal = () => {
    setIsErrorModalOpened(false)
    setErrorFromBackend("")
    setEmail("")
  }

  return {
    email,
    emailError,
    showError,
    sendEmail,
    handleEmail,
    handleBack,
    step,
    backFromResetPassword,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handlePasswordEye,
    handleConfirPasswordEye,
    passwordObj,
    confirmPasswordObj,
    ResetPassword,
    confirmationError,
    isErrorVisible,
    isSuccessModalOpened,
    closeSuccessModal,
    isErrorModalOpened,
    closeErrorModal,
    errorFromBackend
  };
};

export default useForgetPassword;
