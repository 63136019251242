import React from "react";
import { useSignUpMentorSecond } from "../../../../hooks";
import passEyeClose from "../../../../assets/passEyeClose.svg";
import passEyeOpen from "../../../../assets/passEyeOpen.svg";
import { ThankYouSignUp } from "../../../../modals";
import SuccessImage from "../../../../assets/success.png";
import "./SecondForm.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import CheckBox from "../../../../components/Checkbox/Checkbox";

const SecondForm = ({
  goBack,
  password,
  setPassword,
  isSaving,
  setCompleteAddress,
  setZipCode,
  handleSignUp,
  termsCheck,
  setTermsCheck,
  shareDataCheck,
  setShareDataCheck,
  getErrorFromBackend,
  closeErrorModal,
  isErroModalOpened,
  confirmPassword,
  setConfirmPassword,
  passwordError,
  setPasswordError
}) => {
  const {
    country,
    city,
    state,
    zipCode,
    inputRef,
    address,
    showPassword,
    setShowConfirmPassword,
    showConfirmPassword,
    setShowPassword,
    setAddress,
  } = useSignUpMentorSecond(setCompleteAddress, setZipCode);
  return (
    <div className="SecondForm1">
      <div style={{ marginBottom: "16px" }} className="SecondForm__tag">
        <p>Address</p>
        <input
          ref={inputRef}
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className="SecondForm1-cont">
        <div className="SecondForm1-cont__1">
          <div className="input">
            <p>Country</p>
            <input value={country} />
          </div>
          <div className="input">
            <p>City</p>
            <input value={city} />
          </div>
        </div>
        <div className="SecondForm-cont__2">
          <div className="input">
            <p>State</p>
            <input value={state} />
          </div>
          <div className="input">
            <p>Zip Code</p>
            <input value={zipCode} />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "16px" }} className="SecondForm1-cont">
        <div className="SecondForm1-cont__1">
          <div className="input">
            <p>Password*</p>
            <div className="passwordInput">
              <input
                value={password}
                onChange={(e) => {
                  setPasswordError('');
                  setPassword(e.target.value)
                }}
                type={showPassword ? "text" : "password"}
                className="input-password"
              />
              {showPassword ? (
                <img
                  className="input-passimg"
                  onClick={() => setShowPassword((prev) => !prev)}
                  src={passEyeOpen}
                  alt=""
                />
              ) : (
                <img
                  className="input-passimg"
                  onClick={() => {
                    setShowPassword((prev) => !prev)
                  }}
                  src={passEyeClose}
                  alt=""
                />
              )}
            </div>
            {passwordError && (
              <div className="FirstForm__error">
                <img src={Error} alt="" />
                <p>{passwordError}</p>
              </div>
            )}
          </div>
        </div>

        <div className="SecondForm-cont__2">
          <div className="input">
            <p>Confirm Password*</p>
            <div className="passwordInput">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="input-password"
                value={confirmPassword}
                onChange={(e) => {
                  setPasswordError('');
                  setConfirmPassword(e.target.value)
                }}
              />

              {showConfirmPassword ? (
                <img
                  className="input-confpassimg"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  src={passEyeOpen}
                  alt=""
                />
              ) : (
                <img
                  className="input-confpassimg"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  src={passEyeClose}
                  alt=""
                />
              )}
            </div>

          </div>
        </div>
      </div>

      <div className="SecondForm1-agreement">
        <ul>
          <li>
            {/* <input
              value={shareDataCheck}
              onChange={() => setShareDataCheck((prev) => !prev)}
              type="checkbox"
            /> */}
            <CheckBox
              value={shareDataCheck}
              changeHandler={() => setShareDataCheck((prev) => !prev)}
              id={"checkbox2"}
            />
            <p className="mentorSInupsecondPage-lastStep">
              By clicking 'Sign up' I consent to receive updates and
              communications from the Company.
            </p>
          </li>
          <li>
            {/* <input
              value={termsCheck}
              onChange={() => setTermsCheck((prev) => !prev)}
              type="checkbox"
            /> */}
            <CheckBox
              value={termsCheck}
              changeHandler={() => setTermsCheck((prev) => !prev)}
              id={"checkbox1"}
            />
            <p className="mentorSInupsecondPage-lastStep">
              {" "}
              I have read and agree to the {" "}
              <Link target="_blank" to="/privacypolicy">
                Privacy Policy
              </Link>{" "}
              and the website’s{" "}
              <Link target="_blank" to="/terms">
                Terms of Service.
              </Link>
            </p>
          </li>
        </ul>
      </div>

      <div className="SecondForm-navigation">
        <button
          onClick={() => goBack(1)}
          className="SecondForm-navigation__back"
        >
          Back
        </button>
        <button
          disabled={!termsCheck || isSaving}
          onClick={() => termsCheck && !isSaving && !passwordError && handleSignUp()}
          className={classNames("SecondForm-navigation__continue", {
            disable: !termsCheck || isSaving,
          })}
        >
          Sign Up
        </button>
      </div>
      <ThankYouSignUp
        image={SuccessImage}
        isOpened={isErroModalOpened}
        title={"Sign up Failed."}
        EmailCheckText={getErrorFromBackend()}
        close={closeErrorModal}
      />
    </div>
  );
};

export default SecondForm;
