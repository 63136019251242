import React from "react";

import "./Checkbox.scss";

const CheckBox = ({ id, value, changeHandler }) => {
  return (
    <label htmlFor={id}>
      <input type="checkbox" checked={value} onChange={changeHandler} id={id} />
      <span className="checkmark"></span>
    </label>
  );
};

export default CheckBox;
